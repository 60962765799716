<template>
    <v-row>
        <v-col cols="6">
            <v-btn for="image" icon x-large color="primary" class="camera" @click="$refs.image.click()">
                <v-icon>mdi-camera</v-icon>
            </v-btn>
            <input type="file" accept="image/*" ref="image" id="image" name="image" @input="uploader">
        </v-col>
        <v-col cols="6">
            <v-img
                max-height="100"
                max-width="160"
                v-if="getUrl()"
                :src="getUrl()"
            />
            <v-btn color="red" style="color: white" v-if="getUrl()" @click.native="clear" small>
                Excluir
            </v-btn>
        </v-col>
    </v-row>
</template>

<script>
import { resize } from '@/utils/image';

export default {
    name: 'ImageInput',

    props: ['value'],

    data: () => ({
        imagem: null
    }),

    watch: {
        value(v) {
            this.setImagem(v);
        },
    },

    mounted() {
        this.setImagem(this.value);
    },

    methods: {
        setImagem(value) {
            if (!value?.url && !value?.length) {
                this.imagem = [];
                return;
            }
            value?.length && (this.imagem = value[0]);
            value?.url && (this.imagem = value);
        },

        clear() {
            this.imagem = [];
            this.$emit('input', null);
        },

        getUrl() {
            if (!this.imagem?.url) {
                return '';
            }

            const url = this.imagem.tmp
                ? `${this.$urlAPI}/files/tmp/${this.imagem.url}`
                : `${this.$urlAPI}/storage/${this.imagem.url}`;

            return url;
        },

        async uploader(e) {
            const files = e.target.files;
            try {
                const urlUpload = `${this.$urlAPI}/files/upload`;
                const file = files[0];
                const isGif = file.type === 'image/gif';

                const blob = isGif ? file : await resize(file, 400);

                const body = new FormData();
                body.append('file', blob);
                const resp = await fetch(urlUpload, {
                    method: 'POST',
                    headers: {
                        authorization: `Bearer ${sessionStorage.getItem('token')}`,
                    },
                    body,
                });

                const name = file.name.split('.');
                name.pop();

                const dataResp = await resp.json();

                this.$emit('input', {
                    descricao: name.join('.'),
                    file: file,
                    fileName: file.name,
                    tmp: true,
                    url: dataResp.path,
                });
            } finally {
                //
            }
        },
    }
}
</script>

<style scoped>
input[type="file"] {
    display: none;
}

.camera {
    margin: 12px 0 12px;
    cursor: pointer;
}
</style>
