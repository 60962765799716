<template>
  <v-text-field
    :label="label"
    :value="value"
    @input="$emit('input', $event)"
    :type="type"
    :prefix="prefix"
    :placeholder="placeholder"
    :readonly="readonly"
    :filled="readonly"
    outlined
    dense
    hide-details
    class="mt-2"
  />
</template>

<script>
export default {
  name: 'FormInput',

  props: {
    value: {
      type: [String, Number],
      default: '',
    },

    label: {
      type: String,
      default: '',
    },

    prefix: {
      type: String,
      default: '',
    },

    type: {
      type: String,
      default: 'text',
    },

    placeholder: {
      type: String,
      default: '',
    },

    readonly: {
      type: Boolean,
      default: false,
    },
  },
}
</script>
