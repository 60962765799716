<template>
  <!-- eslint-disable-next-line  -->
  <v-dialog v-model="visible" persistent max-width="600px">
    <v-card>
      <v-card-title class="justify-center">
        <v-spacer></v-spacer>
        <span class="text-h5">{{this.form.id ? 'Edição' : 'Cadastro'}} de Produto</span>
        <v-spacer></v-spacer>
        <v-btn icon @click="$emit('close')">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text align="center" justify="center" style="height: 460px; overflow-x: auto;">
          <FormInput
            label="Nome"
            v-model="form.nome"
          />
          <v-textarea
            class="mt-2"
            clearable
            clear-icon="mdi-backspace-outline"
            v-model="form.descricao"
            label="Descrição"
            hide-details
            outlined
          />
          <FormInput
            label="Ordem Loja"
            v-model="form.ordem_loja"
            />
          <v-select
            class="mt-2"
            v-model="form.tipo"
            :items="[{value: 'fisico', text: 'Físico'}, {value: 'digital', text: 'Digital'}]"
            label="Tipo"
            hide-details
            outlined
            dense
          />
          <FormInputMask
            label="Valor Venda"
            prefix="R$"
            placeholder="0.00"
            :mask="['#.##','##.##','###.##','####.##','#####.##']"
            v-model="form.valor_venda"
          />
          <FormInputMask
            label="Valor Promocional"
            prefix="R$"
            placeholder="0.00"
            :mask="['#.##','##.##','###.##','####.##','#####.##']"
            v-model="form.valor_promocional"
          />
          <FormInputMask
            label="Estoque"
            placeholder="0"
            :mask="['#','##','###','####','#####','######','#######']"
            v-model="form.estoque"
            :readonly="form.tipo === 'digital'"
          />
          <v-select
            class="mt-2"
            v-model="form.status"
            :items="[{value: 'ativo', text: 'Ativo'}, {value: 'inativo', text: 'Inativo'}]"
            label="Status"
            hide-details
            outlined
            dense
          />
          <v-select
            class="mt-2"
            v-model="form.periodicidade"
            :items="periodicidades"
            label="Periodicidade"
            hide-details
            outlined
            dense
          />
          <FormInput
            label="Link de Instruções"
            v-model="form.link_instrucoes"
          />
          <FormInput
            label="Chave de Importação de Seriais"
            v-model="form.codigo_importacao"
          />
          <div class="my-2 image-container">
            <span>Imagem (240px x 150px)</span>
            <ImageInput v-model="form.imagens" />
          </div>
      </v-card-text>
      <v-card-actions>
        <v-btn text color="warning" @click="$emit('close')">Cancelar</v-btn>
        <v-spacer />
        <v-btn color="success" @click="salvar" :loading="loading">
          <v-icon>mdi-content-save</v-icon>&nbsp;Salvar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import FormInput from '@/components/FormInput';
import ImageInput from '@/components/ImageInput';
import FormInputMask from '@/components/FormInputMask';

export default {
  name: 'ProductForm',

  components: {
    FormInput,
    ImageInput,
    FormInputMask,
  },

  props: {
    visible: {
      type: Boolean,
      default: false
    },
    formData: {
      type: Object,
      default: Object
    },
  },

  data: () => ({
    loading: false,
    form: {},
    periodicidades: [
      {value: 'mensal', text: 'Mensal'},
      {value: 'bimestral', text: 'Bimestral'},
      {value: 'trimestral', text: 'Trimestral'},
      {value: 'semestral', text: 'Semestral'},
      {value: 'anual', text: 'Anual'},
    ],
  }),

  mounted() {
    this.form = this.formData;
  },

  watch: {
    formData(value) {
      this.form = value ? JSON.parse(JSON.stringify(value)) : {};
    }
  },

  methods: {
    salvar() {
      const id = this.form.id;

      if (id) {
        this.loading = true;
        this.$http.put(`produtos/${id}`, this.form).then(resp => {
          if (resp.data.type === 'warning') {
            this.$toast.error(resp.data.msg);
            return;
          }

          this.$emit('onCadastro', resp.data.data);
          this.$toast.success('Atualizado com sucesso!');
        }).catch(() => {
          this.$toast.error('Não foi possivel concluir a operação');
        }).finally(() => (this.loading = false));
        return;
      }

      this.loading = true;
      this.$http.post('produtos', this.form).then(resp => {
        if (resp.data.type === 'warning') {
          this.$toast.error(resp.data.msg);
          return;
        }

        this.$emit('onCadastro', resp.data.data);
        this.$toast.success('Cadastrado com sucesso!');
      }).catch(() => {
        this.$toast.error('Não foi possivel concluir a operação');
      }).finally(() => (this.loading = false));
    }
  }
}
</script>

<style scoped>
.v-card {
  padding: 0 15px 0 15px;
}

.image-container {
  border: solid 1px #888;
  border-radius: 5px;
}

.v-card__title {
  padding: 16px 10px 10px;
}
</style>
