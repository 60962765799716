<template>
    <AppContainer>
        <span slot="container">
            <div class="pr-2 pl-2">
                <v-toolbar class="ma-2" style="border-radius: 30px;">
                    <v-btn icon link to="/dashboard">
                        <v-icon>mdi-arrow-left</v-icon>
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-toolbar-title v-show="$vuetify.breakpoint.smAndUp">Produtos</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-text-field
                        dense
                        clearable
                        outlined
                        single-line
                        hide-details
                        v-model="search"
                        label="Pesquisar..."
                        append-icon="mdi-magnify"
                    ></v-text-field>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" fab small @click="openCadastro">
                        <v-icon>mdi-plus</v-icon>
                    </v-btn>
                </v-toolbar>
                <v-card elevation="2" class="ma-1">
                    <v-card-text>
                        <v-data-table
                            fixed-header
                            :headers="headers"
                            :items="dados"
                            sort-by="nome"
                            :mobileBreakpoint="0"
                            :loading="loading"
                            :height="$vuetify.breakpoint.height - 180"
                            @click:row="editar"
                            :disable-pagination="true"
                            :hide-default-footer="true"
                            :search="search"
                        >
                            <!-- eslint-disable-next-line -->
                            <template v-slot:item.action="{ item }">
                                <v-btn icon @click.stop="excluir(item.id)" color="red">
                                    <v-icon>mdi-delete</v-icon>
                                </v-btn>
                            </template>
                        </v-data-table>
                    </v-card-text>
                </v-card>
            </div>
            <ProductForm
                :visible="visible"
                :formData="form"
                @onCadastro="onCadastro"
                @close="close"
            />
        </span>
    </AppContainer>
</template>

<script>
import AppContainer from '@/components/AppContainer';
import ProductForm from './ProductForm';

export default {
    name: 'ProductList',

    components: { AppContainer, ProductForm },

    data: () => ({
        visible: false,
        search: '',
        loading: true,
        dados: [],
        form: {},
        headers: [
            { text: 'ID', value: 'id' },
            { text: 'Ordem Loja', value: 'ordem_loja', align: 'center' },
            { text: 'Nome', value: 'nome' },
            { text: 'Tipo', value: 'tipo' },
            { text: 'Valor Venda', value: 'valor_venda', align: 'end'},
            { text: 'Valor Promo', value: 'valor_promocional', align: 'end'},
            { text: 'Estoque', value: 'estoque', align: 'end'},
            { text: 'Status', value: 'status' },
            { text: '', value: 'action', sortable: false, align: 'end'},
        ],
    }),

    mounted() {
        this.consultar();
    },

    methods: {
        consultar() {
            this.loading = true;
            this.dados = [];
            this.$http.get('produtos').then(resp => {
                this.dados = resp.data.data;
                this.loading = false;
            })
            .catch(() => {
                this.$toast.error('Não foi possivel concluir a operação');
                this.loading = false;
            });
        },

        close() {
            this.visible = false;
            this.form = {};
        },

        openCadastro() {
            this.form = { ativo: true };
            this.visible = true;
        },

        onCadastro() {
            this.close();
            this.consultar();
        },

        editar(row) {
            this.form = row;
            this.visible = true;
        },

        async excluir(id) {
            if (confirm('Deseja realmente EXCLUIR?')) {
                this.$http.delete(`produtos/${id}`).then(() => {
                    this.$toast.success('Excluído com sucesso!');
                    this.consultar();
                }).catch(() => {
                    this.$toast.error('Não foi possivel concluir a operação');
                });
            }
        }
    },
}
</script>

<style scoped>
</style>
